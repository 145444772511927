<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto h-full" @close="open = false; $emit('onClose')">
      <div class="flex items-end justify-center text-center">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
          leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div
            class="fixed top-0 sm:top-8 inline-block sm:rounded-md h-screen sm:h-7/8 align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-4xl sm:w-full ">
            <div class="fixed w-auto top-4 right-3 z-10">
              <div class="hidden sm:flex justify-end w-full">
                <Icon name="XIcon" size="6" isOutline color="gray-600" @click="open = false; $emit('onClose')"
                  :onClick="onNoClick" />
              </div>
            </div>
            <div class="flex flex-col h-full overflow-y-auto sm:p-6 px-4">
              <div class="flex items-center mt-2 justify-between">
                <div class="flex">
                  <h1 class="text-gray-900">{{ title }}</h1>
                </div>
                <div class="flex h-full sm:hidden mt-2 ml-2 justify-start">
                  <Icon name="XIcon" size="8" isOutline color="gray-600" @click="open = false; $emit('onClose')"
                    :onClick="onNoClick" />
                </div>
              </div>
              <div class="mt-3 h-screen w-auto text-left">
                <div class="text-lg mb-6" v-html="getAdjustedMessage(currentMilestone && currentMilestone.modalMessage)"></div>
                <div class="mt-3 space-y-1">
                  <div v-for="(milestone, index) in displayingMilestone" :key="milestone && milestone.id" class="space-y-1">
                    <div class="flex items-center space-x-2">
                      <div class="w-5 h-5 rounded-full flex justify-center items-center"
                        :class="isExisted(milestone) ? 'bg-indigo-600' : get(milestone, 'id') === get(currentMilestone, 'id') ? 'bg-gray-200 border border-gray-500' : ' border border-gray-500'">
                        <Icon v-if="isExisted(milestone)" name="CheckIcon" size="4" isOutline color="white" />
                      </div>
                      <Text size="xs" color="black" :weight="get(milestone, 'id') === get(currentMilestone, 'id') ? 'bold' : ''"
                        :content="get(milestone, 'name')" :custom-class="isExisted(milestone) && 'line-through'" />
                    </div>
                    <div v-if="index !== get(displayingMilestone, 'length') - 1" style="margin-left: 9px; width: 2px"
                      class="h-2 bg-gray-400"></div>
                  </div>
                </div>
                <video ref="videoPlayer" class="video-js w-full h-56 sm:h-96 vjs-big-play-centered mt-4"></video>
                <div class="mt-8 pb-40">
                  <Text size="" weight="semibold" color="black" content="Everything you need to know for this step" />
                  <div class="border divide-y w-full mt-3">
                    <button v-for="item in get(currentMilestone, 'resources')" :key="item && item.id"
                      class="px-3 py-2 flex justify-between items-center w-full">
                      <Text size="sm" color="black" :content="item.name" />
                      <div class="flex items-center space-x-3">
                        <Badge :label="item.type" :icon-name="'PlayIcon'" isLeft bg-color="indigo-500" icon-size="6"
                          is-rounded px="3" py="1" text-color="white" />
                      </div>
                    </button>
                  </div>
                  <Text @click="$router.push('/resources')" size="sm" color="indigo-600" decoration="underline"
                    content="All Resources &#8594;" customClass="mt-4 cursor-pointer" />
                </div>
              </div>
            </div>
            <div class="fixed w-full bottom-0 bg-gray-100 h-auto z-20">
              <div class="flex flex-row items-end justify-end my-4 mr-4 sm:mr-3 lg:mr-6">
                <Button @click="() => { open = false; $emit('onClose') }" content="Close" variant="primary"
                  customClass="ml-4 px-4" :isLoading="isLoading" />
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import Icon from '../../../atoms/Icons/Icons.vue'
import Button from '../../../atoms/Button/Button.vue'
import Text from '../../../atoms/Text/Text.vue'
import videojs from 'video.js';
import 'video.js/dist/video-js.css'
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { Controller } from 'swiper';
import 'swiper/swiper.min.css';
import { ref } from "vue";
import Input from '../../../molecules/Inputs/Component/Component.vue'
import Textarea from '../../../molecules/Textareas/Simple/Simple'
import Badge from '../../../molecules/Badge/Basic/Basic.vue'
import { get } from 'lodash'
export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    Icon, Button, Text,
    Swiper,
    SwiperSlide,
    Input, Textarea,
    Badge,
  },
  data() {
    const open = ref(this.isShow);
    return {
      player: null,
      Controller,
      open,
      activeIndex: 0,
      showSwiper: false,
      get,
      options: {
        autoplay: false,
        loop: false,
        controls: true,
        poster: '',
        sources: [
          {
            src: this.currentMilestone?.video?.source,
            type: 'video/mp4'
          }
        ]
      }
    }
  },
  watch: {
    isShow: function (val) {
      console.log(val, 'val')
      this.open = val;
    }
  },
  props: {
    /**
     * Use it for modal title
     */
    title: {
      type: String,
      default: ''
    },
    /**
     * Use it for modal description
     */
    textHtml: {
      type: String,
      default: ''
    },
    /**
     * Use it for modal title
     */
    videoUrl: {
      type: String,
      default: ''
    },
    /**
     * Use it for modal title
     */
    videoTitle: {
      type: String,
      default: ''
    },
    /**
     * Use it for no button text
     */
    noBtnText: {
      type: String,
      default: ''
    },
    /**
     * Use it for no buttona action
     */
    onNoClick: {
      type: Function,
      default: () => {
      }
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    milestones: {
      type: Array,
      default: () => []
    },
    venture: {
      type: Object,
      default: () => { }
    }
  },
  methods: {
    isExisted(milestone) {
      return !!this.milestones.find(m => m?.id === milestone?.id)
    },
    getAdjustedMessage(message) {
      if (message?.includes('{{') && message?.includes('}}')) {
        const value = get(this.venture, message.match(/\{\{([^)]+)\}\}/)[1]?.trim());
        return message.replace(/\{\{([^)]+)\}\}/, value)
      } else {
        return message;
      }
    }
  },
  computed: {
    currentMilestone() {
      const milestones = this.milestones?.sort((a, b) => a?.sortOrder > b?.sortOrder ? -1 : 1);
      return this.state.milestone.milestones?.find(m => m?.sortOrder === milestones[0]?.sortOrder + 1)
    },
    displayingMilestone() {
      const milestones = [];
      const previous = this.state.milestone.milestones?.find(m => m?.sortOrder === this.currentMilestone?.sortOrder - 1);
      if (previous?.id) milestones.push(previous)
      milestones.push(this.currentMilestone)
      const next = this.state.milestone.milestones?.find(m => m?.sortOrder === this.currentMilestone?.sortOrder + 1)
      if (next) milestones.push(next);
      return milestones
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.videoPlayer) {
        this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
          console.log('onPlayerReady', this);
        });
      }
    }, 1000)
  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }
}
</script>
